<!--
 /**
  *
  * 小部件公共组件
  *
  * author: lanbo
  *
  * version: 0.3
  *
  * description: 小部件组件需混合该组件
  *
  * 实例属性：
  *     baseWidgetWidth | 面板容器的宽度
  *
  * 实例方法:
  *     baseGetValueByWidth(widths = {xs, sm, md, lg, xl, span}) | 根据断点宽度返回不同的值，小的断点没有传值时将使用最近的大的断点值
  *
  * changelog
  *     v0.1 2018-12-18 by lanbo | 基本功能
  *     v0.2 2019-01-03 by lanbo | 调整实现方式，修复浮层位置不对问题
  *     v0.3 2019-01-21 by lanbo | 添加了断点宽度判断
  *
  */
-->

<script>
export default {
    props: {
        // 小部件面板标题传递属性，不能删除和修改
        baseWidgetPanelTitle: {
            type: String,
            default: ''
        },
        // 小部件显示内容宽度，供组件更友好地显示做参考
        baseWidgetWidth: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            baseBreakpoints: {xs: 350, sm: 650, md: 940, lg: 1200, xl: 1490}
        };
    },
    methods: {
        baseUtilGetDefaultByList(list = []) {
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                if (element != null) {
                    return element;
                }
            }
        },
        baseSetWidgetPanelTitle(title) {
            this.$emit('update:baseWidgetPanelTitle', title);
        },
        baseGetValueByWidth(widths = {}) {
            const widgetWidth = this.baseWidgetWidth;
            const breakpoints = this.baseBreakpoints;
            if (widgetWidth <= breakpoints.xs) {
                return this.baseUtilGetDefaultByList([widths.xs, widths.sm, widths.md, widths.lg, widths.xl, widths.span]);
            } else if (widgetWidth <= breakpoints.sm) {
                return this.baseUtilGetDefaultByList([widths.sm, widths.md, widths.lg, widths.xl, widths.span]);
            } else if (widgetWidth <= breakpoints.md) {
                return this.baseUtilGetDefaultByList([widths.md, widths.lg, widths.xl, widths.span]);
            } else if (widgetWidth <= breakpoints.lg) {
                return this.baseUtilGetDefaultByList([widths.lg, widths.xl, widths.span]);
            } else if (widgetWidth <= breakpoints.xl) {
                return this.baseUtilGetDefaultByList([widths.xl, widths.span]);
            }

            return widths.span;
        }
    },
    computed: {
        panelTitle() {
            return '';
        }
    },
    watch: {
        panelTitle: {
            immediate: true,
            handler(val) {
                this.baseSetWidgetPanelTitle(val);
            }
        }
    }
};
</script>
