<!--购售电收益-->

<template>
    <widget-framework v-if="dataLoaded" title="购售电收益" :show-border="false" >
        <template #header>
            <div class="headerContainer">
                <span @click="$router.push('/business/powerPurchase')">购售电收益</span>
                <el-date-picker
                    v-model="monthrange"
                    type="monthrange"
                    range-separator="~"
                    start-placeholder="开始月份"
                    end-placeholder="结束月份"
                    value-format="yyyy-MM"
                    style="width: 180px"
                    :clearable="false"
                    @change="monthRangeChange"
                >
                </el-date-picker>
            </div>
        </template>
        <template #content>
            <v-chart :options="opt" :autoresize="true" style="height:100%;width:100%"></v-chart>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import WidgetBase from '@/components/widgets/WidgetBase';
import EchartBase from '@/components/widgets/EchartBase';
import dayjs from 'dayjs';
import numeral from 'numeral';
import {white, tooltip, lightGrey, cyan, grey, blue} from '../constant';

export default {
    name: 'PurchaseAndSaleOfElectricity',
    components: {
        WidgetFramework
    },
    mixins: [WidgetBase, EchartBase],
    data() {
        const startMonth = dayjs().subtract(11, 'month').format('YYYY-MM');
        const endMonth = dayjs().format('YYYY-MM');

        return {
            dataLoaded: false,
            opt: {
                title: {
                    text: '',
                    textStyle: {
                        color: white,
                        fontSize: 14,
                    },
                    top: 'top',
                    left: 'center'
                },
                textStyle: {
                    color: lightGrey
                },
                tooltip: {
                    trigger: 'axis',
                    position: this.setTooltipPosition,
                    axisPointer: {
                        type: 'shadow',
                        shadowStyle: {
                            color: cyan,
                            opacity: 0.2
                        }
                    },
                    backgroundColor: tooltip.backgroundColor,
                    borderColor: tooltip.borderColor,
                    borderWidth: tooltip.borderWidth,
                    extraCssText: tooltip.extraCssText,
                    formatter: (params) => {
                        const {month, income, cost, incomeYoY, incomeMoM, costYoY, costMoM, rate, rateYoY, rateMoM} = params[0].data;
                        const monthStr = dayjs(month).format('YYYY年M月');

                        return `${monthStr}<br/>
                                    售电收益：${this.formatterAmount(income)}；同比：${this.formatterYoYOrMoM(incomeYoY, true)}；环比：${this.formatterYoYOrMoM(incomeMoM, true)}<br/>
                                    购电成本：${this.formatterAmount(cost)}；同比：${this.formatterYoYOrMoM(costYoY, true)}；环比：${this.formatterYoYOrMoM(costMoM, true)}<br/>
                                    收益率：${this.formatterYoYOrMoM(rate)}；同比：${this.formatterYoYOrMoM(rateYoY, true)}；环比：${this.formatterYoYOrMoM(rateMoM, true)}`;
                    }

                },
                legend: {
                    data: ['购电成本', '售电收益'],
                    top: 'bottom',
                    textStyle: {
                        color: cyan,
                        fontSize: 14
                    }
                },
                grid: {
                    top: '40',
                    left: '10',
                    right: '25',
                    bottom: '30',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLabel: {
                        formatter: (value) => {
                            return dayjs(value).format('YY/MM');
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: grey,
                        }
                    },
                },
                yAxis: {
                    name: '单位：万元',
                    nameTextStyle: {
                        color: white
                    },
                    type: 'value',
                    axisLabel: {
                        formatter: (value) => {
                            return (value / 10000);
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: grey,
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                },
                series: [
                    {
                        name: '购电成本',
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: cyan,
                            opacity: 0.2
                        },
                        lineStyle: {
                            color: cyan
                        },
                        itemStyle: {
                            color: cyan
                        },
                        symbol: 'circle',
                        symbolSize: 10,
                        encode: {
                            x: 'month',
                            y: 'cost'
                        }
                    },
                    {
                        name: '售电收益',
                        type: 'line',
                        smooth: true,
                        areaStyle: {
                            color: blue,
                            opacity: 0.2
                        },
                        lineStyle: {
                            color: blue
                        },
                        itemStyle: {
                            color: blue
                        },
                        symbol: 'circle',
                        symbolSize: 10,
                        encode: {
                            x: 'month',
                            y: 'income',
                        }
                    }
                ],
                dataset: {
                    source: []
                }
            },
            monthrange: [startMonth, endMonth]
        };
    },
    methods: {
        formatterAmount(value) {
            return (value == null || value === undefined) ? '~' : value + '元';
        },
        formatterYoYOrMoM(value, showPlus = false) {
            if (showPlus) {
                return (value == null || value === undefined) ? '~' : numeral(value).format('+0.0%');
            }
            return (value == null || value === undefined) ? '~' : numeral(value).format('0.0%');
        },
        monthRangeChange([startMonth, endMonth]) {
            this.listPurchaseAndSaleOfElectricity(startMonth, endMonth);
        },
        listPurchaseAndSaleOfElectricity(startMonth, endMonth) {
            this.$client.listPurchaseAndSaleOfElectricity({startMonth, endMonth}).then((ret) => {
                const {data} = ret || {};
                let totalIncome = 0;
                let totalCost = 0;
                data.forEach((item) => {
                    totalIncome += (item.income || 0);
                    totalCost += (item.cost || 0);
                });
                if (totalCost) {
                    this.opt.title.text = `综合收益率：${this.formatterYoYOrMoM(1.0 * (totalIncome - totalCost) / totalCost)}`;
                } else {
                    this.opt.title.text = `综合收益率：~`;
                }
                this.opt.dataset.source = data || [];
                this.dataLoaded = true;
            });
        }
    },
    mounted() {
        this.listPurchaseAndSaleOfElectricity(this.monthrange[0], this.monthrange[1]);
    }
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.headerContainer {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);

    /deep/.el-input__inner{
        border: 1px solid @lightBlue;
        background-color: rgba(0, 204, 255, 0.2);
        color: @cyan;
        input{
            background-color: unset;
            color: @cyan;
        }
    }
}
</style>
