
<script>
//技术等级颜色表
const colorList = [
    '#83CAFF',
    '#289DF5',
    '#40557D',
    '#C7B7FF',
    '#6D53C8',
    '#502F88',
    '#362476',
    '#FFD89F',
    '#FFD469',
    '#F0B499',
    '#FFA278',
    '#A0DED5',
    '#54C3B5 ',
    '#BAC88B',
    '#6a7447',
    '#F099BA',
    '#B67998',
    '#C7D1E5',
    '#ACDEE4',
    '#4F78D6',
    '#2A06A1'
];
    //柱状图颜色
const barColor = [
    ['#A5E3FF', '#32C0FF'],
    ['#73C3FF', '#289DF5'],
    ['#D2E3FF', '#6CA5FF'],
    ['#3684FF', '#0A5DE0'],
    ['#A5A6FF', '#7375FF'],
    ['#C9A6FF', '#8D52BA'],
    ['#B29DC3', '#6842A0'],

];

export default {
    name: 'EchartBase',
    data() {
        return {
            //颜色列表
            colorList: Object.freeze(colorList),
            barColor: Object.freeze(barColor),
        };
    },
    methods: {
        //修正echarts tooltip位置，防止被遮挡了。
        setTooltipPosition(point, params, dom, rect, size) {
            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
            // 提示框位置
            let x = 0; // x坐标位置
            let y = 0; // y坐标位置

            // 当前鼠标位置
            let pointX = point[0];
            let pointY = point[1];

            // 外层div大小
            // var viewWidth = size.viewSize[0];
            // var viewHeight = size.viewSize[1];

            // 提示框大小
            let boxWidth = size.contentSize[0];
            let boxHeight = size.contentSize[1];

            // boxWidth > pointX 说明鼠标左边放不下提示框
            if (boxWidth > pointX) {
                x = 5;
            } else {
                // 左边放的下
                x = pointX - boxWidth;
            }

            // boxHeight > pointY 说明鼠标上边放不下提示框
            if (boxHeight > pointY) {
                y = 5;
            } else {
                // 上边放得下
                y = pointY - boxHeight;
            }

            return [x, y];
        },
        //返回渐变色系
        getItemColor(dataIndex, seriesIndex) {
            // console.log(colorLevel);
            let index = dataIndex;
            if (dataIndex > 6) {
                index = dataIndex % 6;
            }
            return barColor[index][seriesIndex];
        }
    }
};
</script>
